import React, { useEffect, useState } from "react";
import http from "utils/http";
import { endpoints } from "utils/endpoints";
import Loader from "components/loader";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";
import { fileExtension } from "utils/fileextension";
import FileLoadingInput from "components/FileUploading/fileloadinginput";
import ReUploadFile from "components/FileUploading/reUploadingfile";
import UnsupportedFile from "components/FileUploading/unsupportedfile";
import UploadIcon from "assets/uploadIcon.svg";
import CleanButton from "assets/cleanBtn.svg";

const SourceBox = ({
  sourceText,
  setSourceText,
  outputText,
  setOutputText,
  textareaRef,
  outputRef,
  error,
  setError,
}) => {
  const [wordCount, setWordCount] = useState(0);
  const [loader, setLoader] = useState(false);
  const [shareId, setShareId] = useState("");
  const [fileUploadLoading, setFileUploading] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [sizeLimitError, setSizeLImitError] = useState(false);
  const [file, setFile] = useState(false);


  // useEffect(() => {
  //   const words = sourceText
  //     .trim()
  //     .split(/\s+/)
  //     .filter((word) => word.length > 0);

  //   if (words.length > 1000) {
     
  //     const truncatedText = words.slice(0, 1000).join(" ");
  //     setSourceText(truncatedText); 
  //     setWordCount(1000);           
  //     return; 
  //   }

  //   setWordCount(words.length); 
  // }, [sourceText]);  

 
  // const handleTextChange = (e) => {
  //   const newText = e.target.value;
  //   setSourceText(newText);  
  //   setOutputText("");   
  //   adjustHeight();      
  // };




  const handleTextChange = (e) => {
    const text = e.target.value;

    const words = text
      .trim()
      .split(/\s+/)
      .filter((word) => word.length > 0);

    // if (words.length > 1000) {
    //   e.target.value = words.slice(0, 1000).join(" ");
    //   return;
    // }

    setWordCount(words.length);
    setSourceText(text);
    setOutputText("");
    adjustHeight();
  };

  const adjustHeight = () => {
    const textarea = textareaRef.current;
    textarea.style.height = "auto";
    const newHeight = Math.min(textarea.scrollHeight, 380);
    textarea.style.height = `${newHeight}px`;
  };

  const getAnonymizeText = async () => {
    try {
      setLoader(true);
      const response = await http().post(endpoints.forms.aiTools, {
        tool_name: "anonymizeData",
        user_text: sourceText,
      });
      const newShareId = response?.meta?.share_id;

      setShareId(newShareId);
      window.history.pushState(
        {},
        "",
        `/tools/data-anonymization?share-id=${newShareId}`
      );
      window.location.reload();
      // setOutputText(response?.result?.text);
    } catch (error) {
      setError(error)
      // console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const UpdatreshareId = params.get("share-id");

  const displayShareId = shareId ? shareId : UpdatreshareId;

  useEffect(() => {
    if (!displayShareId) return;
    const fatchAnalysis = async () => {
      setLoader(true);
      try {
        const response = await http().get(
          `${endpoints.forms.aiTools}/${displayShareId}`
        );
        const { user_text: sourceText } = response.meta;

        setSourceText(sourceText);
        setOutputText(response?.result?.text);
      } catch (err) {
        setError(err.message || "An unexpected error occurred.");
      } finally {
        setLoader(false);
      }
    };
    fatchAnalysis();
  }, [displayShareId]);

  const uploadFile = async (e) => {
    setSizeLImitError(false);
    setUploadError(false);
    const selectedFile = e.target.files[0];
    const extension = fileExtension(selectedFile.name);

    if (!extension) {
      setUploadError(true);
      setFile(selectedFile);
      return;
    }
    setFile(selectedFile);
    if (selectedFile.size >= 15000000) {
      alert("File is too large. Please upload file less than 10 MB.");
      setSizeLImitError(true);
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      setFileUploading(true);
      setUploadError(null);
      const res = await http().post(endpoints.forms.toolsFileUpload, formData);

      setSourceText(res.data.text);
    } catch (err) {
      console.log(err, "error");
      setUploadError("Error uploading file. Please try again.");
    } finally {
      setFileUploading(false);
    }
  };

  return (
    <div className="shadow-box p-4 flex-1 text-gray26 bg-white rounded-lg font-opensans">
      <div className="flex justify-between">
        <span className="font-bold text-lg text-[#6E6E6E]"> Source Text</span>
        {sourceText && (
          <img
            src={CleanButton}
            className="cursor-pointer"
            alt="clean-text-button"
            onClick={() => {
              setSourceText("");
              setOutputText("");
              setWordCount(0);
              if (displayShareId) {
                navigate(`/tools/data-anonymization/`);
              }
            }}
          />
        )}
      </div>

      <hr className="border border-[#B0B0B0] mt-2 mb-4" />

      {fileUploadLoading ? (
        <FileLoadingInput file={file} />
      ) : sizeLimitError && !fileUploadLoading ? (
        <ReUploadFile
          file={file}
          setFile={setFile}
          setSizeLImitError={setSizeLImitError}
          uploadFile={uploadFile}
        />
      ) : uploadError && !fileUploadLoading ? (
        <UnsupportedFile
          file={file}
          setSizeLImitError={setSizeLImitError}
          setFile={setFile}
          uploadFile={uploadFile}
          setFileUploadError={setUploadError}
        />
      ) : (
        <textarea
          ref={textareaRef}
          value={sourceText}
          onChange={handleTextChange}
          className={`anonymize_tool  outline-none w-full resize-none text-base border-none ${
            outputRef?.current?.offsetHeight
              ? `min-h-[${outputRef?.current?.offsetHeight + 54}px]`
              : "min-h-[225px]"
          }  max-h-[400px]`}
          placeholder="Enter your text here"
        />
      )}
      <div className="mt-5">
        <div className="flex md:flex-row items-end flex-col gap-2.5 justify-between">
          <div className="flex-1">
            {sourceText ? (
              <p className="text-base text-[#9C9C9C] ">
                {wordCount} words
              </p>
            ) : (
              <>
                <label
                  htmlFor="uploadFile"
                  className="flex gap-2 cursor-pointer"
                >
                  <img src={UploadIcon} alt="upload_icon" />
                  <span className="text-[#858585] font-sm font-opensans font-semibold">
                    Upload File
                  </span>
                </label>
                <input
                  type="file"
                  className="hidden"
                  id="uploadFile"
                  onChange={uploadFile}
                />
              </>
            )}
          </div>
          <button
              disabled={outputText || !sourceText} 
            onClick={getAnonymizeText}
            className={` outline-none font-opensans font-semibold text-base flex items-center justify-center px-6 py-3 rounded-lg gap-3 border ${
              !outputText
                ? "bg-[#5B93FF] border-[#5B93FF]  text-white"
                : "bg-[#E8E8E8] border-[#E8E8E8] "
            }`}
          >
            {loader ? <Loader /> : " Anonymize my data"}
          </button>
        </div>

        <p className="text-[12px] text-[#6E6E6E] bg-[#F0F5FF] p-2 rounded-lg mt-3 font-opensans">
          This AI tool may not catch all sensitive information. Check the output
          before sharing.
        </p>
      </div>
    </div>
  );
};

export default SourceBox;
