import Layout from "components/layout";
import AnonymizationTool from "components/AnonymizationTool";
import SEO from "components/seo";
import React, { useRef } from "react";
import ToolFaq from "components/QualityTools/ToolFaq";
import { AnonymizationToolFaqData } from "components/FAQ/config";


const QualityTool = () => {
  const title = "Data Anonymization";
  const description =
    "Secure your data with our anonymization tool. It redacts or encrypts identifiable information to ensure privacy compliance.";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        slug="/tools/data-anonymization"
      />
      <AnonymizationTool />
      <ToolFaq QaToolFaqData={AnonymizationToolFaqData} />
    </Layout>
  );
};
export default QualityTool;
